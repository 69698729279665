/* WorkSpace Connectors Page Main Container */
.wsConnectorMainContainer {
  background: #171717;
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  padding: 0px 16px;

  &.makeOverFlow {
    overflow: auto;
  }

  @media (max-width: 1300px) {
    min-width: 300px;
  }
}

/*First Row - Navbar Area ---------------------------------------------------*/
.wsConnectorNavBarArea {
  height: 40px;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 900px) {
    margin-bottom: 32px;
  }
}

.preBuildConnectorsCardLayout {
  margin-left: 10px;
  padding-top: 24px;
  padding-bottom: 70px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create 4 equal columns */
  gap: 14px; /* Add gap between cards */

  /* Responsive adjustments */
  @media (max-width: 1000px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* Change to 3 columns on screens up to 768px */
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Change to 2 columns on screens up to 480px */
  }

  @media (max-width: 445px) {
    grid-template-columns: 1fr; /* Change to 1 column on screens up to 320px */
  }
}

/*First Row - Navbar Left Area*/
.wsConnectorNavBarLeftArea {
  display: flex;
  align-items: center;
  font-family: "Articulat CF Medium";
  font-weight: bold;
}

.wsConnectorNavBarTitle {
  height: 24px;
  width: 330px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.wsConnectorNavBarTitle p {
  margin: 0;
  /* Reset margin */
  color: white;
  font-size: 24px;
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  font-weight: bold;
}

/*First Row - Navbar Right Area*/
.wsConnectorNavBarRightArea {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.wsConnectorSearchArea {
  width: 322px;
  height: 40px;
  background-color: #272727;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wsConnectorSearchIcon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
  display: flex;
  align-items: left;
}

.wsConnectorSearchIcon img {
  filter: invert(100%);
  opacity: 72%;
}

.wsConnectorSearchTextArea {
  height: 24px;
  width: 169px;
  display: flex;
  align-items: Left;
  margin-right: 90px;
}

.wsConnectorSearchTextInput {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  font-family: "Articulat CF Medium";
}

.wsConnectorSearchTextInput::placeholder {
  opacity: 72%;
}

.wsConnectorSearchTextInput::placeholder {
  color: white;
  font-size: 16px;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  cursor: pointer;
}

.wsConnectorFilterArea {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.wsConnectorFilterSign {
  height: 24px;
  width: 24px;
  justify-content: center;
  display: flex;
}

// .wsConnectorFilterSign img {
//   height: 20px;
//   width: 20px;
//   object-fit: contain;
// }

.wsConnectorFilterText {
  width: 56px;
  height: 24px;
  top: 80px;
  left: 1346px;
  font-family: "Articulat CF Medium";
  font-size: 18px;
  font-weight: 600;
  line-height: 41.6px;
  text-align: left;
  color: white;
}

.wsConnectorFilterDropDown {
  height: 18px;
  width: 18px;
  display: flex;
  justify-self: center;
  margin-left: 4px;
}

.wsConnectorFilterDropDown img {
  width: 9px;
  height: 5.56px;
  margin-top: 6.22px;
  margin-left: 4.5px;
}

/*Second Row - Card Area ---------------------------------------------------*/
.wsNoConnectorsFound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.wsNoConnectorsFound p {
  color: white;
  font-size: 20px;
}

.wsConnectorCardsArea {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  overflow-y: auto;
  margin-bottom: 60px;
  font-family: "Articulat CF Medium";

  &.extraPaddingTop {
    padding-top: 20px;
  }
}

.wsConnectorCardContainer {
  height: 168px;
  background-color: white;
  display: flex;
  cursor: pointer;
}

.wsConnectorCardLeftLine {
  width: 12px;
  height: 100%;
  background-color: pink;
}

.yellow-gradient {
  background: linear-gradient(
    109.6deg,
    rgb(252, 255, 26) 34.9%,
    rgb(66, 240, 233) 82.5%
  );
}

.pink-gradient {
  background: linear-gradient(
    109.6deg,
    rgb(253, 199, 141) 11.3%,
    rgb(249, 143, 253) 100.2%
  );
}

.blue-gradient {
  background: linear-gradient(
    109.6deg,
    rgb(42, 143, 245) 11.3%,
    rgb(222, 63, 35) 100.2%
  );
}

.orange-gradient {
  background: linear-gradient(
    109.6deg,
    rgb(255, 78, 80) 11.2%,
    rgb(249, 212, 35) 100.2%
  );
}

.wsConnectorCardContentArea {
  width: 100%;
  background-color: #272727;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.wsConnectorCardFirstRow {
  display: flex;
  margin-bottom: 12px;
}

.wsConnectorCardLogo {
  height: 40px;
  width: 40px;
  margin-right: 12px;
  min-width: 40px;
}

.wsConnectorCardLogo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.wsConnectorCardTitleArea {
  height: 40px;
  display: flex;
  flex-direction: column;
}

.wsConnectorCardTitleName {
  height: 22px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.wsConnectorCardTitleName p {
  font-size: 16px;
  color: #fafafa;
  margin: 0;
  line-height: 1;
  max-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wsConnectorCardTitleType {
  height: 16px;
  display: flex;
  align-items: center;
}

.wsConnectorCardTitleType p {
  font-size: 12px;
  color: #fafafa;
  margin: 0;
}

.wsConnectorCardDescription {
  height: 40px;
  width: 100%;
  margin-bottom: 12px;
}

.wsConnectorCardDescription p {
  color: #fafafa;
  font-size: 14px;
  margin-bottom: 14px;
  opacity: 0.63;
}

.wsConnectorCardDisabledBox {
  width: 136px;
  height: 32px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.wsConnectorCardDisabledBox p {
  color: grey;
  font-size: 12px;
  margin: 0;
}

.wsConnectorCardButtonBox {
  height: 32px;
  padding: 8px 12px;
  display: flex;
  border: 1px solid #fafafa;
  width: fit-content;
}

.wsConnectorCardButtonLogo {
  height: 16px;
  width: 16px;
  // background-color: red;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.wsConnectorCardButtonLogo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.wsConnectorCardButtonText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.wsConnectorCardButtonText p {
  color: #fafafa;
  font-size: 12px;
  margin: 0;
}

/*Filter Modal Styles ---------------------------------------------------*/

.wsFilterModal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wsFilterModal::backdrop {
  backdrop-filter: blur(5px);
}

.wsFilterMainContainer {
  display: flex;
  flex-direction: column;
  width: 570px;
  height: 484px;
  position: absolute;
  top: 22%;
  /* Adjust the top position as needed */
  right: 12px;
  /* Adjust the right position as needed */
  z-index: 1;
  overflow: hidden;

  border: 1px solid rgba(250, 250, 250, 0.24);
  background: rgba(39, 39, 39, 0.7);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(8px);
}

.wsFilterMainContainerRow1 {
  height: 56px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.24);
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.wsFilterRow1Title {
  width: 330px;
  height: 24px;
  display: flex;
  align-items: center;
}

.wsFilterRow1Title p {
  margin: 0;
  color: #fafafa;
  font-size: 18px;
  font-weight: 600;
}

.wsFilterRow1CloseButton img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wsFilterRow1CloseButton {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.wsFilterMainContainerRow2 {
  display: flex;
  flex-grow: 1;
}

.wsFilterMainContainerRow2LeftArea {
  width: 220px;
  border-right: 1px solid rgba(250, 250, 250, 0.24);
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.wsFilterModalCategoriesTitleArea {
  height: 24px;
  width: 151px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.wsFilterModalCategoriesTitleArea p {
  margin: 0;
  color: #fafafa;
  font-size: 18px;
  font-weight: 600;
}

.wsFilterModalEachCategoriesArea {
  display: flex;
  // background-color: pink;
}

.wsFilterModalCategoriesAreaCheckBox {
  height: 16px;
  width: 16px;
  margin: 0px 16px 16px 0px;
  cursor: pointer;
}

.wsFilterModalCategoriesAreaCheckBoxInput,
.wsFilterModalSubCategoriesAreaMainBoxInput {
  /* Hide the default checkbox appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Set the custom checkbox icon */
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='%23FAFAFA'%3E%3Cpath d='M4.7248 8.92505L1.9248 6.12505L2.4623 5.58755L4.7248 7.85005L9.5248 3.05005L10.0623 3.58755L4.7248 8.92505Z' fill='%23FAFAFA'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  /* Adjust the size of the icon as needed */
  /* Position the checkbox */
  position: relative;
  cursor: pointer;
}

.wsFilterModalCategoriesAreaCheckBoxInput:checked,
.wsFilterModalSubCategoriesAreaMainBoxInput:checked {
  /* Apply the green color to the checked state */
  background-color: #41bac3;
}

.wsFilterModalCategoriesAreaSelectedCheckBox {
  height: 16px;
  width: 16px;
  border: 1px solid #fafafa;
  margin: 0px 16px 16px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: green;
}

.wsFilterModalCategoriesAreaSelectedCheckBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wsFilterModalCategoriesAreaColorBox {
  height: 16px;
  width: 4px;
  margin-right: 8px;
}

.wsFilterModalCategoriesAreaTextBox {
  height: 16px;
  width: 143px;
  display: flex;
  align-items: center;
}

.wsFilterModalCategoriesAreaTextBox p {
  margin: 0;
  color: #fafafa;
  font-size: 14px;
  font-weight: 600;
}

.wsFilterMainContainerRow2RightArea {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Align items to the top */
  align-items: center;
}

.wsFilterModalOptionsArea1 {
  width: 100%;
  height: calc(100% / 3);
  /* Divide the height equally for 3 boxes */
  overflow-y: auto;
}

.wsFilterModalOptionsArea1MainBox {
  border-bottom: 1px solid grey;
  padding: 16px;
}

.wsFilterModalOptionsArea1Row1 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.wsFilterModalOptionsArea1TitleColorBar {
  height: 24px;
  width: 4px;
  margin-right: 8px;
}

.wsFilterModalOptionsArea1Title {
  height: 16px;
  width: 310px;
  display: flex;
  align-items: center;
}

.wsFilterModalOptionsArea1Title p {
  margin: 0;
  color: #fafafa;
  font-size: 18px;
  font-weight: 600;
}

.wsFilterModalSubCategoriesArea {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 16px;
}

.wsFilterModalSubCategoriesAreaMainBox {
  height: 48px;
  display: flex;
  align-items: center;
}

.wsFilterModalSubCategoriesAreaMainBoxInput {
  margin-right: 12px;
}

.wsFilterModalSubCategoriesAreaMainBoxLabel {
  font-size: 14px;
  font-weight: 500;
  color: #fafafa;
}

.wsFilterModalOptionsArea2 {
  width: 240px;
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
}

.wsFilterModalOptionsArea2 p {
  color: #fafafa;
  font-size: 18px;
  margin: 0;
  text-align: center;
}

.wsFilterMainContainerRow3 {
  height: 64px;
  border-top: 1px solid rgba(250, 250, 250, 0.24);
  padding: 16px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.wsFilterModalClearButton,
.wsFilterModalApplyButton {
  height: 32px;
  width: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fafafa;
  cursor: pointer;
}

.wsFilterModalClearButton {
  margin-right: 16px;
}

.wsFilterModalClearButton p,
.wsFilterModalApplyButton p {
  margin: 0;
  color: #fafafa;
  font-size: 12px;
}

/*Stepper CSS Styles ---------------------------------------------------*/

.stepper-interface {
  display: flex;
  align-items: center;

  .step {
    display: flex;
    align-items: center;
    margin: 0 10px;

    &.active .step-icon {
      background-color: #41bac3;
    }

    &.inactive .step-icon {
      background-color: #888888;
    }

    .step-icon {
      width: 30px;
      height: 30px;
      color: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;

      img {
        width: 16.19px;
        height: 16.25px;
      }
    }

    .step-text {
      margin-left: 10px;
      color: #fafafa;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .step-arrow {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      width: 50px;
      height: 8.9px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: #007bff;
      z-index: -1;
    }
  }

  .step:last-child .step-arrow:before {
    display: none;
  }
}
