.container {
  padding: 0 !important;
}

.cardcontainer {
  background-color: #2f2f2f;
  border-radius: 12px;
  flex-wrap: nowrap !important;
  width: 100%;
  // min-width: 256px;
  // max-width: 384px;
  max-height: 100%;
}

.leftline {
  width: 4px;
  background-color: #47ccd6;
  height: 106px;
  border-radius: 12px;
  // @include line-color($category);
}
.leftlineOrange {
  width: 4px;
  background-color: #ee6f47;
  height: 106px;
  border-radius: 12px; // @include line-color($category);
}
.categoryOrange {
  font-size: 12px;
  font-weight: 500;
  color: #ee6f47;
  font-family: Articulat CF Medium;
}

.connectorlogoarea {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4b4b4b;
  border-radius: 4px;
}

.connectornamearea {
  font-size: 16px;
  font-weight: 600;
  color: #fafafa;
}

.connectorname {
  display: flex;
  align-items: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  font-weight: bold;
}

.title {
  font-size: 10px;
  font-weight: 500;
  color: #777777;
}

.connectorcategory {
  font-size: 12px;
  font-weight: 500;
  color: #ececec;
}

.connectorcategoryarea {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  font-weight: bold;
}

.category {
  font-size: 12px;
  color: #47ccd6;
  font-family: "Articulat CF Medium";
}

.description {
  font-family: "Articulat CF Medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #f6f6f6;
}
