.header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}

.terminalconfigmain {
  background-color: #171717;
  width: 100%;
  height: 100vh;
  //  overflow:scroll;
}
.terminalconfigcomponent {
  @media (min-width: 768px) {
    height: calc(100vh - 100px);
  }

  &.addFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.terminalconfigleft {
  display: flex;
  flex-direction: column;
}
.terminalPadding {
  height: 100%;
}

.terminalconfigright {
  margin: 0px 16px;
}

.title-configcard {
  font-family: "Articulat CF Medium";
  font-size: 24px;
  font-weight: 500;
  text-align: left;
}

.tags-confgicard {
  font-family: "Articulat CF Medium";
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: var(--grey-1, #777777);
}
.name-configcard {
  font-size: 16px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  padding-top: 16px;
}

.build-content {
  font-family: "Articulat CF Medium";
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 18.2px;
  text-align: left;
}
// .content-row {
//   margin-top: -10px;
// }
.inner-col {
  margin-top: 10px;
  width: 330px;
  height: 24px;
  line-height: 41.6px;
}
.content-col {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: 0px;
}
.text-align {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inner-row {
  width: 330px;
  height: 24px;
  line-height: 41.6px;
}
.build-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.build-status-logs {
  height: 100%;
  margin-top: -48px;
}
.build-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.button-style {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  // margin-top: 32px;
  margin-right: 45px;
}

.logs-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.build-container {
  margin-top: 25px;
}
