.buildListTitle {
  margin: 10px;
  border-radius: 10px;
}
.buildCardTitleSection {
  height: 100px;
  display: flex;
  align-items: center;
  .buildCardTitleSectionName {
    padding: 20px;
  }
}
.textTitleColorPrimary {
  color: #fafafa;
}
.textTitleColorSecondary {
  color: #a3a3a3ae;
}
