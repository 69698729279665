/* 
! Logo Design for landing Page 
*/
.addNewCustomConnector {
  position: absolute;
  bottom: 40px;
  left: 20px;
  z-index: 999;

  .addNewCustomConnectorImg {
    cursor: pointer;
  }

  .addNewCustomConnectorImg:hover {
    animation: shake 0.3s;

    @keyframes shake {
      0% {
        transform: rotate(0deg);
      }
      10% {
        transform: rotate(-10deg);
      }
      20% {
        transform: rotate(10deg);
      }
      30% {
        transform: rotate(0deg);
      }
      40% {
        transform: rotate(10deg);
      }
      50% {
        transform: rotate(-10deg);
      }
      60% {
        transform: rotate(0deg);
      }
      70% {
        transform: rotate(-5deg);
      }
      80% {
        transform: rotate(5deg);
      }
      90% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-5deg);
      }
    }
  }
}
.custom-modal {
  animation: slideInRight 0.8s forwards;
  top: 4.2vw;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.connectorsPageModal {
  position: fixed;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-radius: 10px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.3),
    0 3px 6px rgba(0, 0, 0, 0.23);
  height: 100%;
  width: 50%;
  z-index: 1000;
  background-color: #313131;
  right: 0;
  .connectorsPageModalCloseBtn {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  $indicator-color-red: #ff5161;
  $indicator-color-green: #90ee90;
  $indicator-color-yellow: #ffcc00;
  $indicator-shadow-red: 0px 0px 2px 3px #ff5179;
  $indicator-shadow-green: 0px 0px 2px 3px #90ef99;
  $indicator-shadow-yellow: 0px 0px 2px 3px #ffcc09;

  $indicator-size: 2em;

  .beaconIndicatorGreen {
    position: absolute;
    background-color: $indicator-color-green;
    height: $indicator-size;
    width: $indicator-size;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: grow 0.3s 1 linear;

    &::before {
      left: 0;
      position: absolute;
      content: "";
      height: $indicator-size;
      width: $indicator-size;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: $indicator-shadow-green;
      animation: active 2s infinite linear;
    }
  }

  .beaconIndicatorRed {
    position: absolute;
    background-color: $indicator-color-red;
    height: $indicator-size;
    width: $indicator-size;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: grow 0.3s 1 linear;

    &::before {
      left: 0;
      position: absolute;
      content: "";
      height: $indicator-size;
      width: $indicator-size;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: $indicator-shadow-red;
      animation: active 2s infinite linear;
    }
  }

  .beaconIndicatorYellow {
    position: absolute;
    background-color: $indicator-color-yellow;
    height: $indicator-size;
    width: $indicator-size;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: grow 0.3s 1 linear;

    &::before {
      left: 0;
      position: absolute;
      content: "";
      height: $indicator-size;
      width: $indicator-size;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: $indicator-shadow-yellow;
      animation: active 2s infinite linear;
    }
  }

  @keyframes grow {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes active {
    0% {
      transform: scale(0.1);
      opacity: 1;
    }
    70% {
      transform: scale(2.5);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}

.addedNewConnectors {
  border-radius: 10px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.3),
    0 3px 6px rgba(0, 0, 0, 0.23);
  height: 100%;
  width: 80%;
  z-index: 1000;
  background-color: #313131;
  .addedNewConnectorsCloseBtn {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

/* 
! Tidy Up Button Styling
*/
.tidyUpContainer {
  position: fixed;
  bottom: 16px;
  left: 16px;
  border: 1px solid rgba(250, 250, 250, 1);
  z-index: 3000;
  height: 48px;
  width: 196px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(39, 39, 39, 1);
}

.tidyUpIcon {
  height: 24px;
  width: 24px;
  // background-color: rgba(250, 250, 250, 1);
}

.tidyUpText {
  font-size: 18px;
  margin-left: 10px;
  color: white;
}

/* 
! React Flow Chat Icon
*/
.reactFlowChatIcon {
  position: fixed;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 3000;
}

.selectedConnectorDescription {
  height: 30px;
  transition: transform 0.5s ease-in-out;
  transform: translateY(-30%);
}

.modalPopOver {
  border-radius: 10px;
  position: fixed;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.3),
    0 3px 6px rgba(0, 0, 0, 0.23);
  height: 30%;
  width: 80%;
  z-index: 3000;
  background-color: #313131;
  .modalPopOverStyle {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}
