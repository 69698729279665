.mainContainer {
  background-color: #272727;
  border-radius: 15px;
  padding: 15px;
  color: #fafafa;
  max-height: 70vh;
  overflow: auto;
}

.statusDot {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
}

.innerRow {
  display: flex;
  flex-wrap: wrap;

  &.mb {
    margin-bottom: 10px;
  }

  &.flexEnd {
    justify-content: flex-end;
  }

  &.flexStart {
    justify-content: flex-start;
  }

  &.middle {
    justify-content: center;
  }
}

.p {
  margin: 0px 0px 0px 5px;
}

.add-icon,
.delete-icon {
  width: 30px;
  height: 30px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.delete-icon {
  background-color: #f00;
  /* Red color for delete icon */
  color: #fff;
  /* White color for the "x" */
}
